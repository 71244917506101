import {
  CLOSE_SIMULTANEOUS_LOGINS_SPOTTED_PANEL,
  OPEN_SIMULTANEOUS_LOGINS_SPOTTED_PANEL,
  SET_ANONYMOUS,
  SET_USER,
} from '@App/store/actions/authentication';

export const emptyPermissions = {
  isAdmin: false,
  isStaff: false,
  isImpersonator: false,
  canManageUser: false,
  canManagePlaylist: false,
  canManageAlbum: false,
  canManageBrief: false,
  canRequestLicence: false,
  canDownload: false,
  hasStreamingFeature: false,
  isInAllowedCountry: true,
  displaySubscriptionBanner: false,
};

const initialState = {
  id: null,
  profile: null,
  permissions: emptyPermissions,
  simultaneousLoginsSpotted: false,
};

/**
 * Authentication
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function authentication(state = initialState, action): any {
  const { type, payload } = action;

  switch (type) {
    case SET_USER:
      return {
        ...state,
        id: payload.id,
        profile: payload.profile,
        permissions: payload.permissions,
      };

    case SET_ANONYMOUS:
      return initialState;

    case OPEN_SIMULTANEOUS_LOGINS_SPOTTED_PANEL:
      return {
        ...state,
        simultaneousLoginsSpotted: true,
      };

    case CLOSE_SIMULTANEOUS_LOGINS_SPOTTED_PANEL:
      return {
        ...state,
        simultaneousLoginsSpotted: false,
      };

    default:
      return state;
  }
}
